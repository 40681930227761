<template>
    <div id="app">
        <button v-on:click="resetTestData" v-show="showResetTestData">Reset testdata</button>
        <b-container>
            <h1 class="page_header_text">{{ 'PageHeaderText' | translate }}</h1>
            <Section v-if="$route.path !== '/terms'"
                     v-bind:title="section_1.titleText | translate"
                     v-bind:content="section_1.contentText | translate"
                     v-bind:currentComponent="section_1.currentComponent"></Section>
            <Section v-if="$route.path !== '/terms'"
                     v-bind:title="AdditionalServiceSection.titleText | translate"
                     v-bind:content="AdditionalServiceSection.contentText | translate"
                     v-bind:currentComponent="AdditionalServiceSection.currentComponent"></Section>
            <ClientInfo v-if="$route.path !== '/terms'"></ClientInfo>
            <router-view></router-view>
        </b-container>
        <footer class="py-4 py-md-5 border-0 footer-blue" style="background-color: #00325c;">
            <div class="container footer-container">
                <div class="row justify-content-center text-left" style="color: #fff">
                    <div class="col-12 col-sm-6 col-lg-3 mb-3">
                        <div class="d-flex justify-content-lg-end">
                            <ul class="list-unstyled">
                                <li>
                                    <h5>{{ 'Address' | translate }}</h5>
                                </li>
                                <li class="infotext">{{ 'SveaCompanyName' | translate }}</li>
                                <li class="infotext">{{ 'SveaCompanyAddress' | translate }}</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-3 mb-3">
                        <div class="d-flex justify-content-lg-center">
                            <ul class="list-unstyled">
                                <li>
                                    <h5>{{ 'CustomerService' | translate }}</h5>
                                </li>
                                <li>
                                    <u class="infotext">{{ 'CustomerServicePhone' | translate }}</u><br />
                                    <a class="infotext" style="color: #FFF; text-decoration: underline;" :href="'mailto:' + $options.filters.translate('CustomerServiceEmail')">{{ 'CustomerServiceEmail' | translate }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-3 mb-3">
                        <div class="d-flex justify-content-lg-center">
                            <ul class="list-unstyled">
                                <li>
                                    <h5>{{ 'CustomerServiceOpenHours' | translate }}</h5>
                                </li>
                                <li class="infotext">
                                    {{ 'CustomerServiceOpenHoursValue' | translate }}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-3 mb-3">
                        <div class="d-flex justify-content-lg-start">
                            <ul class="list-unstyled">
                                <li>
                                    <h5>{{ 'Privacy' | translate }}</h5>
                                </li>
                                <li class="infotext">
                                    <a style="color: #FFF; text-decoration: underline;" target="_blank" href="http://cdn.svea.com/sveaekonomi/anvandavillkor_svea.com_2020_1.pdf?_ga=2.151898800.886956375.1594804419-246565347.1584600485">{{ 'PrivacyGeneralTerms' | translate}}</a><br />
                                </li>
                                <li class="infotext">
                                    <a style="color: #FFF; text-decoration: underline;" target="_blank" href="https://www.svea.com/se/sv/om-oss/svea-ekonomi/personuppgiftsbehandling/">{{ 'HowWeHandleYourPersonalInfo' | translate }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <div style="background-color: #00aece; height: 10px;"></div>
        <div style="background-color: #f2fdff; text-align: center;" class="py-4">
            <a href="/">
                <img class="logo" src="./assets/Svea_Primary_RGB_small.png" />
            </a>
            <div class="mt-3" style="color: #00325c; font-size: 0.9rem; font-weight: 600;">{{ 'SveaCompanyCopyrightMasthead' | translate }}</div>
        </div>
    </div>
</template>

<script>
    if (window.location.href.indexOf("http:") != -1) {
        window.location.href = window.location.href.replace("http:", "https:");
    }

    import Section from './components/Section.vue';
    import ClientInfo from './components/ClientInfo.vue';
    //import axios from 'axios';
    export default {
        name: 'App',
        components: {
            Section, ClientInfo
        },
        data() {
            return {
                section_1: {
                    titleText: 'PriceListHeaderText',
                    contentText: 'PriceListContentText',
                    currentComponent: 'Pricelist'
                },
                AdditionalServiceSection: {
                    titleText: 'AddonServicesHeaderText',
                    contentText: '',
                    currentComponent: 'AdditionalService'
                },
                showResetTestData: false
            }
        },
        async created() {
            let res = await fetch("/api/onboarding/showresetdatabutton");
            var json = await res.json();
            this.showResetTestData = json;
        },
        methods: {
            async resetTestData() {
                var response = await fetch("/api/onboarding/resettestdata", { method: "POST" })
                if (!response.ok) {
                    alert("failed");
                } else {
                    alert("data was reset");
                }
            }
        }
    }
</script>

<style lang="scss">
#app {
  font-family: Asap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 30px;
  background-color: $bg-color;
  width: 100%;
}
</style>
