<template>
    <div style="margin-top:15px;">
        <b-row class="table-header-text align-items-end">
            <b-col class="text-left" v-bind:class="textAlignment(ind)" :cols="setCols(obj)" v-for="(obj, ind) in config" v-bind:key="ind">{{ obj.title | translate }}</b-col>
        </b-row>
        <!--<hr style="margin: 5px 0 5px 0" />-->
        <b-row class="table-content-text align-items-end" v-for="(row, index) in rowData" v-bind:key="index">
            <b-col class="text-left align-self-end" align-self="center" v-bind:class="textAlignment(ind)" :cols="setCols(obj)" v-for="(obj, ind) in config" v-bind:key="ind">{{ row[obj.key] | translate | setCurrency }}</b-col>
        </b-row>
    </div>
</template>

<script>

    export default {
        name: 'v-table',
        props: {
            config: {
                type: Array,
                default() { [] },
                required: false
            },
            rowData: {
                type: Array,
                default() { [] },
                required: false
            }
        },
        methods: {
            textAlignment: function (index) {
                let align = 'text-left';
                if (index == 0) {
                    align = 'text-left';
                } else {
                    align = 'text-right';
                }
                return align;
            },
            setCols: function (obj) {
                return obj.columnSettings.cols;
            }
        }
    }
</script>

<style scoped>

</style>