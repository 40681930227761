<template>
    <div>
        <v-table
                 class="mt-5"
                 :config="dummyData.yearlyFee.config"
                 :rowData="dummyData.yearlyFee.data">

        </v-table>
        <div class="accordion">
            <div class="accordion-item"
                 v-bind:class="accordionClasses">
                <div class="accordion-body">
                    <div class="accordion-content">
                        <!-- Pricelist data, maybe the accordion can be made into a separate component later? //Christian -->
                        <v-table class="mt-5"
                                 v-bind:config="dummyData.atg.config"
                                 v-bind:rowData="dummyData.atg.data">

                        </v-table>
                        <v-table class="mt-5"
                                 :config="dummyData.otherAtg.config"
                                 :rowData="dummyData.otherAtg.data">

                        </v-table>
                        <div class="content-text mt-5 p-0 text-left" style="white-space: pre-wrap;">{{latePaymentProvisionText | translate}}</div>
                        <div class="content-text mt-4 p-0 text-left" style="white-space: pre-wrap;">{{pricelistInfoText | translate}}</div>
                        <!-- Had to add "isOpen" check here because otherwise the icon is shown even if accordion is closed: Christian 2021-09-22 -->
                        <div v-show="isOpen" class="content-text mt-5 p-0 text-left">
                            <div style="
                                width: 20px;
                                height: 20px;
                                border-radius: 50%;
                                background-color: #00829a;
                                color: white;
                                position: absolute;">
                                <img style="margin: 0 0 2px 6px" src="../assets/document.png" />
                            </div>
                        </div>
                        <router-link class="mx-4" style="color:#00829a" :to="{ name: 'termsRoute'}" target="_blank">
                            {{ 'Read' | translate }} {{'TermsFor' | translate}} {{'ProductPackageName' | translate}}
                        </router-link>
                    </div>
                </div>
                <div class="accordion-link">
                    <div v-on:click.stop="toggleAccordion">
                        <div class="accordion-header-div">
                            {{ accordionLinkText | translate }} <font-awesome-icon :icon="accordionLinkIcon" />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    var dummydata = {
        yearlyFee: {
            config: [
                { title: 'YearlyFee', key: 'yearlyFee', columnSettings: { cols: '8' } },
                { title: 'Price', key: 'price', columnSettings: { cols: '4' } }
            ],
            data: [
                { yearlyFee: 'ProductPackageName', price: 'ProductPackageValue' }
            ]
        },
        atg: {
            config: [
                { title: 'Action', key: 'atg', columnSettings: { cols: '6' } },
                { title: 'UnpaidClaim', key: 'unpaidDemand', columnSettings: { cols: '3' } },
                { title: 'WithdrawnCase', key: 'withdrawnCase', columnSettings: { cols: '3' } }
            ],
            data: [
                {
                    id: 1,
                    atg: 'DebtcollectionFee',
                    unpaidDemand: 'DebtcollectionFeeUnpaidClaimValue',
                    withdrawnCase: 'DebtcollectionFeeWithdrawnCaseValue'
                },
                {
                    id: 2,
                    atg: 'ApplicationForInjunctionToPay',
                    unpaidDemand: 'ApplicationForInjunctionToPayUnpaidClaimValue',
                    withdrawnCase: 'ApplicationForInjunctionToPayWithdrawnCaseValue'
                },
                {
                    id: 3,
                    atg: 'AgentFees',
                    unpaidDemand: 'AgentFeesUnpaidClaimValue',
                    withdrawnCase: 'AgentFeesWithdrawnCaseValue'
                },
                {
                    id: 4,
                    atg: 'Foreclosure',
                    unpaidDemand: 'ForeclosureUnpaidClaimValue',
                    withdrawnCase: 'ForeclosureWithdrawnCaseValue'
                }
            ]
        },
        otherAtg: {
            config: [
                { title: 'OtherAction', key: 'otherAtg', columnSettings: { cols: '6' } },
                { title: 'Price', key: 'price', columnSettings: { cols: '6' } }
            ],
            data: [
                { otherAtg: 'ExtraInvestigation', price: 'ExtraInvestigationValue' },
                { otherAtg: 'SveaLegalFees', price: 'SveaLegalFeesValue' },
                { otherAtg: 'PostSurveillance', price: 'PostSurveillanceValue'}
            ]
        }
    };

    var pricelistInfoText = 'PriceListFooterText'
    var latePaymentProvisionText = 'LatePaymentProvisionText'

    //Just simulating data to test component //Christian
    
    export default {
        name: 'pricelist',
        data() {
            return {
                isOpen: false,
                pricelistInfoText: pricelistInfoText,
                latePaymentProvisionText: latePaymentProvisionText,
                dummyData: dummydata
            }
        },
        methods: {
            toggleAccordion: function () {
                this.isOpen = !this.isOpen;
            }
        },
        computed: {
            accordionClasses: function () {
                return {
                    'is-closed': !this.isOpen,
                    'is-primary': this.isOpen
                };
            },
            accordionLinkText: function () {
                let title;
                if(this.isOpen){
                    title = 'ClosePriceList';
                } else {
                    title = 'OpenPriceList';
                }
                return title;
            },
            accordionLinkIcon: function () {
                let icon;
                if (this.isOpen) {
                    icon = 'minus-circle';
                } else {
                    icon = 'plus-circle'
                }
                return icon;
            }

        }
    }
</script>