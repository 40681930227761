import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import Table from './components/Table.vue'
import Pricelist from './components/Pricelist.vue'
import ClientInfo from './components/ClientInfo.vue'
import Terms from './components/Terms.vue'
import AdditionalService from "@/components/AdditionalService"
import LoaderSpinner from "./components/LoaderSpinner.vue"
import './filters/vTranslation'
import './filters/vSetCurrency'
import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './styles/scss/bootstrap.scss'
import { i18n, loadLanguage } from './i18n.js'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueCompositionAPI from '@vue/composition-api'
import Vuelidate from 'vuelidate'
import store from '../src/store'

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(VueRouter)
Vue.use(VueCompositionAPI)
Vue.use(Vuelidate)

library.add(fas)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('v-table', Table)
Vue.component('Pricelist', Pricelist)
Vue.component('ClientInfo', ClientInfo)
Vue.component('Terms', Terms)
Vue.component('AdditionalService', AdditionalService)
Vue.component('LoaderSpinner', LoaderSpinner);

const router = new VueRouter({
    routes: [
        { path: '/terms', name: 'termsRoute', component: Terms }
    ] 
});

new Vue({
    render: h => h(App),
    i18n,
    async created() {
        //Set what language the app should load with: Christian 2021-03-15
        await loadLanguage('sv-SE');
    },
    router,
    store
}).$mount('#app')
