<template>
    <b-card class="clientInfo text-left">
        <!--<pre>{{JSON.stringify($v.bankId.orgNumber, null, 2)}}</pre>
        <pre>{{JSON.stringify($v.contactInfo.bankAccountNumber, null, 2)}}</pre>-->
        <!-- Bank id -->
        <b-row class="mt-5">
            <b-col cols="2" lg="1" sm="2">
                <div class="container">
                    <div v-bind:class="stepClass(1)" v-if="activeStep == 1">1</div>
                    <div class="done-step d-flex justify-content-center align-items-center"
                         v-if="activeStep > 1">
                        <img src="../assets/checkmark.png" alt="1" />
                    </div>
                </div>
            </b-col>
            <b-col cols="6" lg="6" sm="6">
                <h1>{{ title1 | translate }}</h1>
            </b-col>
            <b-col cols="4"
                   lg="4"
                   sm="4"
                   class="link-to-section"
                   v-if="activeStep > 1 && activeStep < 4">
                <span v-on:click="editBankId()">{{ 'ChangeInfo' | translate }}</span>
            </b-col>
        </b-row>
        <b-row class="bank-id">
            <b-col cols="2" lg="1" sm="2">
                <div class="container h-100">
                    <div class="dots d-flex justify-content-center align-items-center"></div>
                </div>
            </b-col>
            <b-col cols="10" lg="11" sm="10">
                <b-row>
                    <b-col lg="6" sm="10">
                        <div v-show="activeStep === 1 && !isLoggedIn">
                            <span>{{ content1 | translate }}</span>
                            <b-form class="mt-5">
                                <b-form-group>
                                    <input id="organisationNumber"
                                           class="svea-field w-100"
                                           v-model="bankId.orgNumber"
                                           type="text"
                                           :placeholder="'OrganizationNumber' | translate"
                                           @blur="$v.bankId.orgNumber.$touch(); updateOrgNumber()" />
                                    <label for="organisationNumber" style="color:red; margin:auto; font-size: 14px;" v-if="!$v.bankId.orgNumber.required && $v.bankId.orgNumber.$dirty && $v.bankId.orgNumber.$model == ''">Du måste fylla i ett organisationsnummer</label>
                                    <label for="organisationNumber" style="color:red; margin:auto; font-size: 14px;" v-if="!$v.bankId.orgNumber.isValidOrgnumber && $v.bankId.orgNumber.$dirty && $v.bankId.orgNumber.$model != ''">Du måste fylla i ett korrekt organisationsnummer</label>
                                </b-form-group>

                                <b-form-group v-show="enableFakeSsn" id="input-group-2"
                                              label-for="ssn"
                                              :description="'SsnDescriptionText' | translate"
                                              :class="{ 'form-group--error': $v.bankId.ssn.$error }">
                                    <input id="ssn"
                                           class="svea-field w-100"
                                           v-model="bankId.ssn"
                                           type="text"
                                           :placeholder="'PersonalNumber' | translate"
                                           @blur="formatPersonalNumber(); $v.bankId.ssn.$touch(); updatePersonalNumber();" />
                                    <label for="ssn" style="color:red; margin:auto; font-size: 14px;" v-if="!$v.bankId.ssn.required && $v.bankId.ssn.$dirty && $v.bankId.ssn.$model == ''">Du måste fylla i ditt personnummer</label>
                                    <label for="ssn" style="color:red; margin:auto; font-size: 14px;" v-if="!$v.bankId.ssn.isValidSsn && $v.bankId.ssn.$dirty && $v.bankId.ssn.$model != ''">Du måste fylla i ett korrekt personnummer</label>
                                </b-form-group>
                                <b-button :disabled="!$v.bankId.orgNumber.isValidOrgnumber" v-b-modal.modal-1 class="svea-button" @click="validateSsn">{{ 'IdentifyWithBankId' | translate }}</b-button>
                                <!--<b-button v-b-modal.confirmation-modal class="svea-button">{{ 'CONFIRMATION MODAL' | translate }}</b-button>-->
                                <div v-show="!$v.bankId.$anyError">
                                    <!--new bank id auth modal-->
                                    <b-modal centered hide-footer no-close-on-esc no-close-on-backdrop hide-header-close ref="bankid-modal" id="modal-1">
                                        <div>
                                            <img class="bankid-logo" src="../assets/BankID_logo.png" alt="bankid" />
                                            <p class="my-4 modal-title">{{'AuthWithBankId' | translate}}</p>
                                            <loader-spinner v-bind:isLoading="(!showQr && showLoader)"></loader-spinner>
                                            <div v-show="isQr && showQr">
                                                <div class="text-center pl-5 pr-5">
                                                    <span class="d-block">1. {{'InfoOpenBankId' | translate}}</span>
                                                    <span class="d-block">2. {{'InfoClickOnQr' | translate}}</span>
                                                    <span class="d-block">3. {{'InfoPointCameraQr' | translate}}</span>
                                                </div>
                                                <div class="row align-self-center mt-2">
                                                    <div class="col">
                                                        <img src=" " class="qr-image" ref="qrAuthImg" alt="qrcode"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <p class="content-text p-2" v-show="showModalErrorText" style="text-align: center; font-size: 16px;">{{ modalErrorText }}</p>
                                            <b-button v-show="!showLoader" class="svea-button" data-device-type="current" @click="initEidAuth(false)">{{'StartBankIdOnThisDevice' | translate}}</b-button>
                                            <b-button v-show="!showLoader" class="svea-button mt-2" data-device-type="other" @click="initEidAuth(true)">{{'StartBankIdOnAnotherDevice' | translate}}</b-button>
                                            <b-button v-show="!disableCancelBtn" class="cancel-button mt-2 mb-5"
                                                      @click="cancelEidAuth">{{'Cancel' | translate}}</b-button>
                                        </div>
                                    </b-modal>
                                    <!--new bank id auth modal-->

                                    <b-modal ref="already-registered-modal"
                                             id="modal-2"
                                             centered
                                             hide-footer>
                                        <p class="my-4 modal-title">{{'CompanyAlreadyExists' | translate}}</p>
                                        <span>{{'LoginToRegisterNewErrands' | translate}}</span>
                                        <br />
                                        <b-button @click="openKundwebInNewWindow" class="svea-button mt-5">Logga in</b-button>
                                        <b-button onclick="window.open('https://www.svea.com', '_blank')" class="cancel-button mt-2 mb-5">{{'GoToSvea' | translate}}</b-button>
                                    </b-modal>
                                    <b-modal ref="not-connected-modal"
                                             id="modal-3"
                                             centered
                                             hide-footer>
                                        <p class="my-4 modal-title">{{'NoCompanyFound' | translate}}</p>
                                        <span>{{'NoCompanyFoundErrorText' | translate}}</span>
                                        <b-button class="svea-button mt-3 mb-5"
                                                  @click="hideNoCompanyFoundModal">{{'Ok' | translate}}</b-button>
                                    </b-modal>
                                    <b-modal ref="non-user-modal" id="modal-4" centered hide-footer>
                                        <p class="my-4 modal-title">{{'CompanyAlreadyExists' | translate}}</p>
                                        <span>{{'CompanyAlreadyExistsErrorText' | translate}}.</span>
                                        <p>
                                            <a style="text-decoration: underline; color: #00829a" :href="phoneNumberHref">{{'SveaPhoneNumber' | translate}}</a>
                                        </p>
                                        <b-button onclick="window.open('https://www.svea.com', '_blank')" class="cancel-button mt-3 mb-5">{{'GoToSvea' | translate}}</b-button>
                                    </b-modal>
                                    <b-modal ref="user-exist-no-company-modal" id="modal-4" centered hide-footer>
                                        <p class="my-4 modal-title">{{'UserAlreadyExists' | translate}}</p>
                                        <span>{{'ContactUs' | translate}}</span>
                                        <p>
                                            <a style="text-decoration: underline; color: #00829a" :href="phoneNumberHref">{{'SveaPhoneNumber' | translate}}</a>
                                        </p>
                                        <b-button onclick="window.open('https://www.svea.com', '_blank')" class="cancel-button mt-3 mb-5">{{'GoToSvea' | translate}}</b-button>
                                    </b-modal>
                                </div>
                            </b-form>
                        </div>

                        <!-- Login information -->
                        <div v-if="isLoggedIn" class="login-info">
                            <!-- Warning card -->
                            <div v-if="hasWarning">
                                <b-card class="mt-3 warning-section">
                                    <b-row>
                                        <b-col lg="2">
                                            <div class="alert-icon d-flex justify-content-center align-items-center">
                                                <img src="../assets/alert.png" alt="alert" />
                                            </div>
                                        </b-col>
                                        <b-col lg="10">
                                            <h1>{{'ContactUs' | translate}}</h1>
                                            <span>
                                                {{'ContactUsDescriptionText' | translate}}.
                                            </span>
                                        </b-col>
                                    </b-row>
                                    <b-row class="my-2">
                                        <b-col lg="10" offset-lg="2">
                                            <a style="text-decoration: underline" :href="phoneNumberHref">{{'SveaPhoneNumber' | translate}}</a>
                                        </b-col>
                                    </b-row>
                                </b-card>
                            </div>

                            <h1 class="mt-3">{{ companyName }}</h1>
                            <span>{{ streetAddress }}</span><br />
                            <span>{{ city }}</span><br /><br />
                            <span>{{'OrganizationNumber' | translate}}:{{ bankId.orgNumber }}</span><br />
                            <span>{{'CompanyType' | translate}}: {{ companyType }}</span><br /><br />
                            <span>{{ companyInfo.representativeName }} {{ bankId.ssn }}</span>
                        </div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="10" lg="11" sm="10">
                        <div class="mt-5 rectangle"></div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="10" lg="11" sm="10" class="mt-5">
                        <div class="helper"></div>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>

        <!-- Contact info -->
        <b-row>
            <b-col cols="2" lg="1" sm="2">
                <div class="container">
                    <div v-bind:class="stepClass(2)" v-if="activeStep <= 2">2</div>
                    <div class="done-step d-flex justify-content-center align-items-center"
                         v-if="activeStep > 2">
                        <img src="../assets/checkmark.png" alt="2" />
                    </div>
                </div>
            </b-col>
            <b-col cols="6" lg="6" sm="6">
                <h1>{{ title2 | translate }}</h1>
            </b-col>
            <b-col cols="4"
                   lg="4"
                   sm="4"
                   class="link-to-section"
                   v-if="activeStep > 2 && activeStep < 4">
                <span v-on:click="editContactInfo()">{{'ChangeInfo' | translate}}</span>
            </b-col>
        </b-row>
        <b-row class="about-company">
            <b-col cols="2" lg="1" sm="2">
                <div class="container h-100">
                    <div class="dots d-flex justify-content-center align-items-center"></div>
                </div>
            </b-col>
            <b-col cols="10" lg="11" sm="10">
                <b-row>
                    <b-col cols="10" lg="7" sm="10">
                        <div>
                            <span v-if="!isContactInfoSubmited">{{ content2 | translate }}</span>
                            <div v-if="activeStep === 2">
                                <b-form @submit="submitInfo" class="mt-5">
                                    <div class="about-company-form-section">
                                        <h1>{{'ContactInfo' | translate}}</h1>
                                        <b-form-group>
                                            <input id="telNumber"
                                                   v-model="contactInfo.phoneNumber"
                                                   class="svea-field w-100"
                                                   type="text"
                                                   :placeholder="'PhoneNumber' | translate"
                                                   @blur="$v.contactInfo.phoneNumber.$touch();updatePhoneNumber()" />
                                            <label for="telNumber" style="color:red; margin:auto; font-size: 14px;" v-if="!$v.contactInfo.phoneNumber.required && $v.contactInfo.phoneNumber.$dirty && $v.contactInfo.phoneNumber.$model == ''">Du måste fylla i ett telefonnummer</label>
                                        </b-form-group>

                                        <b-form-group>
                                            <input id="emailAdress"
                                                   v-model="contactInfo.emailAddress"
                                                   class="svea-field w-100"
                                                   type="text"
                                                   :placeholder="'EmailAddress' | translate"
                                                   @blur="$v.contactInfo.emailAddress.$touch();updateEmailAddress()" />
                                            <label for="emailAdress" style="color:red; margin:auto; font-size: 14px;" v-if="!$v.contactInfo.emailAddress.required && $v.contactInfo.emailAddress.$dirty && $v.contactInfo.emailAddress.$model == ''">Du måste fylla i en mejladress</label>
                                        </b-form-group>
                                    </div>
                                    <div class="about-company-form-section">
                                        <h1>{{'AccountForPayments' | translate}}</h1>
                                        <b-form-group id="input-group-2"
                                                      label-for="bank"
                                                      :description="'BankAccountDescriptionText' | translate">
                                            <input id="bank"
                                                   v-model="contactInfo.bankAccountNumber"
                                                   class="svea-field w-100"
                                                   type="text"
                                                   :placeholder="'BankAccountNumber' | translate"
                                                   @blur="$v.contactInfo.bankAccountNumber.$touch();updateBankAccountNumber()" />
                                        </b-form-group>
                                        <b-form-group id="input-group-2"
                                                      label-for="post"
                                                      :description="'PostAccountDescriptionText' | translate">
                                            <input id="post"
                                                   v-model="contactInfo.postAccountNumber"
                                                   class="svea-field w-100"
                                                   type="text"
                                                   :placeholder="'PostAccountNumber' | translate"
                                                   @blur="$v.contactInfo.postAccountNumber.$touch();updatePostAccountNumber()" />
                                        </b-form-group>
                                        <label for="bank" style="color:red; margin:auto; font-size: 14px;" v-if="!$v.contactInfo.bankAccountNumber.requiredUnlessPg && !$v.contactInfo.bankAccountNumber.requiredUnlessBg && $v.contactInfo.bankAccountNumber.$dirty">Du måste fylla i ett postgironummer eller ett bankgironummer</label>
                                        <!--<label for="post" style="color:red; margin:auto; font-size: 14px;" v-if="!$v.contactInfo.postAccountNumber.requiredUnlessBg && $v.contactInfo.postAccountNumber.$dirty && $v.contactInfo.postAccountNumber.$model == ''">Du måste fylla i ett postgironummer</label>-->
                                    </div>
                                    <div class="about-company-form-section">
                                        <h1>{{'InvoiceRecipient' | translate}}</h1>
                                        <b-form-group id="input-group-2"
                                                      label-for="invoiceReference"
                                                      :description="'InvoiceRecipientDescriptionText' | translate">
                                            <input id="invoiceReference"
                                                   v-model="contactInfo.invoiceReference"
                                                   class="svea-field w-100"
                                                   type="text"
                                                   :placeholder="'InvoiceReference' | translate"
                                                   @blur="$v.contactInfo.invoiceReference.$touch();updateInvoiceReference()" />
                                            <label for="invoiceReference" style="color:red; margin:auto; font-size: 14px;" v-if="!$v.contactInfo.invoiceReference.required && $v.contactInfo.invoiceReference.$dirty && $v.contactInfo.invoiceReference.$model == ''">Du måste fylla i en fakturareferens</label>
                                        </b-form-group>

                                        <b-form-group id="input-group-2"
                                                      label-for="invoiceReferenceEmail"
                                                      :description="'RecipientEmailDescriptionText' | translate">
                                            <input id="invoiceReferenceEmail"
                                                   v-model="contactInfo.invoiceReferenceEmail"
                                                   class="svea-field w-100"
                                                   type="text"
                                                   :placeholder="'EmailAddress' | translate"
                                                   @blur="$v.contactInfo.invoiceReferenceEmail.$touch();updateInvoiceReferenceEmail()" />
                                            <label for="invoiceReferenceEmail" style="color:red; margin:auto; font-size: 14px;" v-if="!$v.contactInfo.invoiceReferenceEmail.required && $v.contactInfo.invoiceReferenceEmail.$dirty && $v.contactInfo.invoiceReferenceEmail.$model == ''">Du måste fylla i en e-postadress</label>
                                        </b-form-group>

                                        <b-button type="submit" class="svea-button">{{'Continue' | translate}}</b-button>
                                    </div>
                                </b-form>
                            </div>
                        </div>
                        <div v-if="isContactInfoSubmited" class="login-info">
                            <h1 class="mt-3">{{'ContactInfo' | translate}}</h1>
                            <span>{{ contactInfo.phoneNumber }}</span><br />
                            <span>{{ contactInfo.emailAddress }}</span>
                            <h1 class="mt-4">{{'AccountForPayments' | translate}}</h1>
                            <p style="margin:0; padding:0;" v-if="contactInfo.bankAccountNumber">BG {{ contactInfo.bankAccountNumber }}</p>
                            <p style="margin:0; padding:0;" v-if="contactInfo.postAccountNumber">PG {{ contactInfo.postAccountNumber }}</p>
                            <span>{{ companyName }}</span>
                            <h1 class="mt-4">{{'InvoiceRecipient' | translate}}</h1>
                            <span>{{ contactInfo.invoiceReference }}</span><br />
                            <span>{{ contactInfo.invoiceReferenceEmail }}</span>
                        </div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="10" lg="11" sm="10">
                        <div class="mt-5 rectangle"></div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="10" lg="11" sm="10" class="mt-5">
                        <div class="helper"></div>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>

        <!-- All Services -->
        <b-row>
            <b-col cols="2" lg="1" sm="2">
                <div class="container">
                    <div v-bind:class="stepClass(3)" v-if="activeStep <= 3">3</div>
                    <div class="done-step d-flex justify-content-center align-items-center"
                         v-if="activeStep > 3">
                        <img src="../assets/checkmark.png" alt="3" />
                    </div>
                </div>
            </b-col>
            <b-col cols="6" lg="6" sm="6">
                <h1>{{ title3 | translate }}</h1>
            </b-col>
            <b-col cols="4" lg="4" sm="4"> </b-col>
        </b-row>

        <b-row class="selected-services">
            <b-col cols="2" lg="1" sm="2"> </b-col>
            <b-col cols="10" lg="11" sm="10">
                <b-row>
                    <b-col cols="10" lg="7" sm="10">
                        <span>{{ content3 | translate }}</span>
                        <div v-if="activeStep === 3">
                            <div class="mt-5">
                                <b-row style="border-bottom: 1px solid lightgrey">
                                    <b-col cols="6"
                                           lg="6"
                                           sm="6"
                                           class="text-left service-title">{{ summaryOfChoices.ProductPackage.title | translate }}</b-col>
                                    <b-col cols="6"
                                           lg="6"
                                           sm="6"
                                           class="text-right service-details">
                                        {{ summaryOfChoices.ProductPackage.amount | translate | setCurrency  }}
                                        {{ summaryOfChoices.ProductPackage.payment | translate }}
                                    </b-col>
                                </b-row>
                                <b-row style="border-bottom: 1px solid lightgrey" v-if="addReminder">
                                    <b-col cols="6"
                                           lg="6"
                                           sm="6"
                                           class="text-left service-title">{{ summaryOfChoices.Reminder.title | translate }}</b-col>
                                    <b-col cols="6"
                                           lg="6"
                                           sm="6"
                                           class="text-right service-details">
                                        {{ summaryOfChoices.Reminder.amount | translate | setCurrency  }}
                                        {{ summaryOfChoices.Reminder.payment | translate }}
                                    </b-col>
                                </b-row>
                                <b-row style="border-bottom: 1px solid lightgrey" v-if="delayCompensation != 3">
                                    <b-col cols="6"
                                           lg="6"
                                           sm="6"
                                           class="text-left service-title">{{ summaryOfChoices.LateFeeAtReminder.title | translate }}</b-col>
                                    <b-col cols="6"
                                           lg="6"
                                           sm="6"
                                           class="text-right service-details">
                                        {{ summaryOfChoices.LateFeeAtReminder.amount | translate  }} {{summaryOfChoices.LateFeeAtReminder.currency}}
                                        {{ summaryOfChoices.LateFeeAtReminder.payment | translate }}
                                    </b-col>
                                </b-row>
                            </div>
                            <div class="mt-5">
                                <b-row>
                                    <b-col cols="12" lg="12" sm="12">
                                        <!-- TODO: MAKE THIS DOCUMENT IMG TO A COMPONENT, WE NEED TO RE USE IT LATER: Christian 2021-07-02 -->
                                        <div style="
                                            width: 20px;
                                            height: 20px;
                                            border-radius: 50%;
                                            background-color: #00829a;
                                            color: white;
                                            position: absolute;">
                                            <img style="margin: 0 0 2px 6px" src="../assets/document.png" />
                                        </div>
                                        <span class="ml-4">
                                            <a href="https://www.svea.com/sv-se/om-oss/integritet" target="_blank">{{'HowWeHandleYourPersonalInfo' | translate}}</a>
                                        </span>
                                    </b-col>
                                </b-row>
                                <b-row class="mt-3" v-if="addReminder">
                                    <b-col cols="12" lg="12" sm="12">
                                        <b-form-checkbox id="checkbox-2"
                                                         name="checkbox-2"
                                                         v-model="acceptGdprDeal" style="display: inline">
                                            {{'IAccept' | translate}}
                                        </b-form-checkbox>&nbsp;
                                        <a href="gdprattachment.pdf" target="_blank" download="GDPR-avtal Bilaga Inkasso 2019-04-01.pdf">GDPR-avtalet</a>
                                    </b-col>
                                </b-row>
                                <b-row class="mt-3">
                                    <b-col cols="12" lg="12" sm="12">
                                        <b-form-checkbox id="checkbox-3"
                                                         name="checkbox-3"
                                                         v-model="acceptTerms" style="display: inline">
                                            {{'IAccept' | translate}}
                                            <!-- <a _target="blank" href="/terms">villkoren för Företag bas</a>  -->
                                        </b-form-checkbox>&nbsp;
                                        <router-link :to="{ name: 'termsRoute'}" target="_blank">
                                            {{'TermsFor' | translate}} {{'ProductPackageName' | translate}}
                                        </router-link>
                                    </b-col>
                                </b-row>
                                <b-row class="mt-3">
                                    <b-col cols="12" lg="8" sm="12">
                                        <b-button v-b-modal.bankid-sign-modal :disabled="!enableSignButton" type="submit" class="svea-button">
                                            {{'SignAndLogin' | translate}}
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>
                        <div v-if="activeStep > 3">
                            <p class="content-text my-4 text-left" style="white-space:pre-wrap">{{'ConfirmationModalContentText' | translate}}</p>
                            <form id="kundwebbLoginForm" v-bind:action="kundwebbLoginFormAction" method="POST">
                                <input name="data" type="hidden" v-bind:value="kundwebbLoginFormData" />
                                <b-button v-on:click="gotoKundwebb" :disabled="!enableSignButton" class="svea-button mt-2 mb-5 w-100">{{'ConfirmationModalBtnText' | translate}}</b-button>
                            </form>
                        </div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="10" lg="11" sm="10" class="mt-5">
                        <div class="helper"></div>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>

        <!-- new bankid signing modal -->
        <b-modal no-close-on-esc no-close-on-backdrop hide-header-close ref="bankid-sign-modal" id="bankid-sign-modal" centered hide-footer>
            <div class="container py-2 px-4 text-center">
                <img class="bankid-logo" src="../assets/BankID_logo.png" alt="bankid" />
                <p class="my-4 modal-title">{{'SignWithBankId' | translate}}</p>
                <loader-spinner v-bind:isLoading="!showQr && showLoader"></loader-spinner>
                <div v-show="isQr && showQr">
                    <div class="text-center pl-5 pr-5">
                        <span class="d-block">1. {{'InfoOpenBankId' | translate}}</span>
                        <span class="d-block">2. {{'InfoClickOnQr' | translate}}</span>
                        <span class="d-block">3. {{'InfoPointCameraQr' | translate}}</span>
                    </div>
                    <div class="row align-self-center mt-2">
                        <div class="col">
                            <img src=" " class="qr-image" ref="qrSignImg" alt="qrcode" />
                        </div>
                    </div>
                </div>
                <p class="content-text p-2" v-show="showModalErrorText" style="text-align: center; font-size: 16px;">{{ modalErrorText }}</p>
                <b-button v-show="!showLoader" class="svea-button" data-device-type="current" @click="registerNewClient(false)">{{'StartBankIdOnThisDevice' | translate}}</b-button>
                <b-button v-show="!showLoader" class="svea-button mt-2" data-device-type="other" @click="registerNewClient(true)">{{'StartBankIdOnAnotherDevice' | translate}}</b-button>
                <b-button v-show="!disableCancelBtn" class="cancel-button mt-2 mb-5"
                          @click="cancelEidSign">{{'Cancel' | translate}}</b-button>
            </div>
        </b-modal>
        <!-- new bankid signing modal -->

        <!-- Confirmation modal -->
        <b-modal ref="confirmation-modal" id="confirmation-modal" centered hide-footer>
            <div class="container py-2 px-4 text-center">
                <loader-spinner v-bind:isLoading="showLoader"></loader-spinner>
                <div v-show="!showLoader">
                    <div class="mx-auto my-2" style="width:80px; height:80px; border-radius:50%; background-color: #d7f1ba; padding: 16px 0 5px 0;">
                        <img src="../assets/Success - icon.svg" alt="success" />
                    </div>
                    <h1 class="modal-title my-4">{{'ConfirmationModalHeaderText' | translate}}</h1>
                    <p class="content-text my-4 text-center" style="white-space:pre-wrap">{{'ConfirmationModalContentText' | translate}}</p>
                    <form id="kundwebbLoginForm" v-bind:action="kundwebbLoginFormAction" method="POST">
                        <input name="data" type="hidden" v-bind:value="kundwebbLoginFormData" />
                        <b-button v-on:click="gotoKundwebb" :disabled="!enableSignButton" class="svea-button mt-2 mb-5">{{'ConfirmationModalBtnText' | translate}}</b-button>

                    </form>
                </div>
            </div>
        </b-modal>

        <!-- General error modal -->
        <b-modal ref="error-modal" id="error-modal" centered hide-footer>
            <div class="container py-2 px-4 text-center">
                <div class="mx-auto my-2">
                    <img src="../assets/alert-large.svg" alt="alert" />
                </div>
                <h2 class="modal-title my-4">{{'Något gick fel. Var vänlig försök igen.'}}</h2>
                <p v-show="correlationId !== ''" class="content-text my-4 text-center" style="white-space:pre-wrap">Felkod: {{ correlationId }}</p>
            </div>
            <b-button class="btn cancel-button btn-secondary mt-2" aria-label="close" @click="$bvModal.hide('error-modal')">{{'Cancel' | translate}}</b-button>
        </b-modal>
    </b-card>

</template>
<script>
    import { required, requiredIf, requiredUnless } from 'vuelidate/lib/validators';
    import axios from 'axios';
    import LoaderSpinner from './LoaderSpinner.vue'
    import QRious from 'qrious/dist/qrious.js'

    function cleanDigitString(digitString) {
        return digitString.replace(/\D/g, '');
    }

    var isValidSsn = function (ssn) {
        var cleanSsn = cleanDigitString(ssn)
        const regexp = new RegExp('^[0-9]{8}(\-)?[0-9]{4}$');
        const zerosRegExp = new RegExp('^[0]{8}(\-)?[0]{4}$');
        return regexp.test(cleanSsn) && !zerosRegExp.test(cleanSsn);
    }

    async function generateHash(input) {
        const data = new TextEncoder().encode(input);
        const hash = await crypto.subtle.digest('SHA-256', data);
        const hashArray = Array.from(new Uint8Array(hash));                     // convert buffer to byte array
        const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
        console.log("crypto", hashHex);
        return hashHex;
    }

    function isValidLuhn(pnr) {
        var number;
        var checksum = 0;
        for (var i = pnr.length - 1; i >= 0; i--) {
            number = parseInt(pnr.charAt(i));
            if (i % 2 == 1) {
                checksum += number;
            } else {
                checksum += (number * 2) > 9 ? (number * 2) - 9 : number * 2;
            }
        }

        return checksum % 10 == 0;
    }

    function isValidOrgnumber(orgnr) {
        var orgnumber = cleanDigitString(orgnr);
        if (orgnumber.length < 10 || orgnumber.length > 12 || orgnumber.length == 11) {
            return false;
        }
        return true;
    }

    function formatOrganisationNumber(orgnr) {
        if (orgnr.substring(0, 2) != '16') {
            orgnr = '16' + orgnr;
        }
        orgnr = orgnr.replace('-', '');
        return orgnr;
    }

    var summaryOfChoices = {
        ProductPackage: {
            id: 1,
            title: "ProductPackageName",
            amount: "ProductPackageValue",
            currency: "kr",
            payment: "PerYear",
        },
        Reminder: {
            id: 2,
            title: "Reminders",
            amount: "RemindersValue",
            currency: "kr",
            payment: "PerReminder",
        },
        LateFeeAtReminder: {
            id: 3,
            title: "LateFeeAtReminder",
            amount: "LateFeeAtReminderValue",
            currency: "%",
            payment: "OfPaidCompensation",
        },
    };

    export default {
        name: "clientInfo",
        components: {
            LoaderSpinner
        },
        data() {
            return {
                title1: "ChooseCompany",
                title2: "AboutCompany",
                title3: "TermsAndSelectedServices",
                content1:
                    "ChooseCompanyDescriptionText",
                content2:
                    "AboutCompanyDescriptionText",
                content3:
                    "TermsAndSelectedServicesDescriptionText",
                isLoggedIn: false,
                hasWarning: false,
                isValidSsn: false,
                isValidOrgnumber: false,
                isContactInfoSubmited: false,
                correlationId: "",
                statusInterval: null,
                totalAttemptForStatus: 0,
                showLoader: false,
                disableCancelBtn: false,
                bankIdLoadingText: "Initializing",
                modalErrorText: "",
                bankIdSessionExpired: false,
                showBankIdLoadingText: false,
                showModalErrorText: false,
                bankIdSignToken: null,
                abortAuth: false,
                abortSign: false,
                canBecomeClient: false,
                acceptGdprDeal: false,
                acceptTerms: false,
                kundwebbLoginFormAction: "",
                kundwebbLoginFormData: "",
                signersReferenceId: "",
                documentReferenceId: "",
                activeStep: 1,
                bankId: {
                    orgNumber: "",
                    ssn: "",
                },
                contactInfo: {
                    phoneNumber: "",
                    emailAddress: "",
                    bankAccountNumber: "",
                    postAccountNumber: "",
                    invoiceReference: "",
                    invoiceReferenceEmail: "",
                },
                companyInfo: {
                    companyName: "",
                    street: "",
                    city: "",
                    companyType: "",
                    representativeName: "",
                },
                summaryOfChoices: summaryOfChoices,
                bankIdOrderRef: null,
                bankIdAutoStartToken: null,
                qrInterval: null,
                showQr: false,
                qr: new QRious(),
                qrElement: null,
                isQr: false,
                fakeSsn: "",
                authMode: ""
            }
        },
        computed: {
            personalNumber() {
                return this.$store.state.personalNumber;
            },
            organisationNumber() {
                return this.$store.state.organisationNumber;
            },
            phoneNumber() {
                return this.$store.state.phoneNumber;
            },
            emailAddress() {
                return this.$store.state.emailAddress;
            },
            bankAccountNumber() {
                return this.$store.state.bankAccountNumber;
            },
            postAccountNumber() {
                return this.$store.state.postAccountNumber;
            },
            invoiceReference() {
                return this.$store.state.invoiceReference;
            },
            invoiceReferenceEmail() {
                return this.$store.state.invoiceReferenceEmail;
            },
            companyName() {
                return this.$store.state.companyName;
            },
            companyType() {
                return this.$store.state.companyType;
            },
            streetAddress() {
                return this.$store.state.streetAddress;
            },
            postalCode() {
                return this.$store.state.postalCode;
            },
            city() {
                return this.$store.state.city;
            },
            phoneNumberHref() {
                return "tel:" + this.$options.filters.translate('SveaPhoneNumber');
            },
            addReminder() {
                return this.$store.state.addReminder
            },
            delayCompensation() {
                return this.$store.state.delayCompensation
            },
            enableSignButton() {
                //We need dynamic conditionals on the button because GDPR deal is only shown if reminder is true
                //If reminder is false then the sign button should be enabled if you only check the terms: Christian 2022-02-09
                if (this.addReminder) {
                    if (this.acceptTerms && this.acceptGdprDeal) {
                        return true;
                    }
                } else {
                    if (this.acceptTerms) {
                        return true;
                    }
                }
                return false;
            },
            enableFakeSsn() {
                if (window.location.href.includes('.dev.') || window.location.href.includes('.test') || window.location.href.includes('localhost')) {
                    return true;
                }
                return false;
            }
        },
        methods: {
            openBankIdApp(authType) {
                //bankid url
                const url = new URL("bankid:///");
                url.searchParams.append("autostarttoken", this.bankIdAutoStartToken);

                const isIOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
                let redirectParam = "";
                if (this.isMobileDevice()) {
                    if (isIOS) {
                        if (navigator.userAgent.match('CriOS')) {
                           redirectParam = "googlechromes://";
                        } else if (navigator.userAgent.match('EdgiOS')) {
                            redirectParam = "microsoft-edge-https://";
                        } else if (navigator.userAgent.match('FxiOS')) {
                            redirectParam = "firefox://";
                        } else {
                            encodeURIComponent(window.location);
                        }
                    } else {
                        redirectParam = null;
                    }
                }

                url.searchParams.append("redirect", redirectParam);
                window.location = url.href;
            },
            isMobileDevice() {
                let isMobile = {
                    Android: function () { return navigator.userAgent.match(/Android/i); },
                    BlackBerry: function () { return navigator.userAgent.match(/BlackBerry/i); },
                    iOS: function () { return navigator.userAgent.match(/iPhone|iPad|iPod/i); },
                    Opera: function () { return navigator.userAgent.match(/Opera Mini/i); },
                    Windows: function () { return navigator.userAgent.match(/IEMobile/i); },
                    any: function () {
                        return (isMobile.Android() ||
                            isMobile.BlackBerry() ||
                            isMobile.iOS() ||
                            isMobile.Opera() ||
                            isMobile.Windows());
                    }
                };
                return isMobile.any();
            },
            updatePersonalNumber() {
                if (this.$v.bankId.ssn.isValidSsn) {
                    this.$store.commit('changeOrAdd', { personalNumber: this.bankId.ssn.trim() });
                }
            },
            updateOrgNumber() {
                this.$store.commit('changeOrAdd', { organisationNumber: this.bankId.orgNumber.trim() });
            },
            updatePhoneNumber() {
                this.$store.commit('changeOrAdd', { phoneNumber: this.contactInfo.phoneNumber });
            },
            updateEmailAddress() {
                this.$store.commit('changeOrAdd', { emailAddress: this.contactInfo.emailAddress });
            },
            updateBankAccountNumber() {
                this.$store.commit('changeOrAdd', { bankAccountNumber: this.contactInfo.bankAccountNumber });
            },
            updatePostAccountNumber() {
                this.$store.commit('changeOrAdd', { postAccountNumber: this.contactInfo.postAccountNumber });
            },
            updateInvoiceReference() {
                this.$store.commit('changeOrAdd', { invoiceReference: this.contactInfo.invoiceReference });
            },
            updateInvoiceReferenceEmail() {
                this.$store.commit('changeOrAdd', { invoiceReferenceEmail: this.contactInfo.invoiceReferenceEmail });
            },
            validateSsn() {
                this.$v.bankId.ssn.$touch();
                var ssn = this.bankId.ssn.trim()
                this.isValidSsn = isValidSsn(ssn);
                if (this.isValidSsn) {
                    this.fakeSsn = ssn;
                    this.authMode = "fake";
                } else {
                    this.authMode = "test";
                }
            },
            formatPersonalNumber() {
                var ssn = this.bankId.ssn.trim();
                if (this.$v.bankId.ssn.isValidSsn) {
                    var formattedSsn = ssn;
                    if (ssn.indexOf('-') == -1) {
                        if (ssn.substring(0, 2) != '19' || ssn.substring(0, 2) != '20') {
                            //formattedSsn += ''
                            formattedSsn = ssn.substring(0, 8) + '-' + ssn.substring(8, ssn.length)
                        } else {
                            formattedSsn = ssn.substring(0, 6) + '-' + ssn.substring(6, ssn.length)
                        }

                        this.bankId.ssn = formattedSsn;
                    }
                }
            },
            validateOrgnr() {
                this.$v.bankId.orgNumber.$touch();
                var orgnr = this.bankId.orgNumber.trim();
                this.isValidOrgnumber = isValidOrgnumber(orgnr);
            },
            setCanBecomeClientdata(canBecomeClientResponse) {
                var vueComp = this;
                vueComp.canBecomeClient = canBecomeClientResponse.canBecomeClient;
                console.log("Check complete, CanBecomeClient:", vueComp.canBecomeClient);
                if (!vueComp.canBecomeClient) {
                    vueComp.hasWarning = true;
                }
                if (canBecomeClientResponse.companyInfo != null) {
                    var compInfo = canBecomeClientResponse.companyInfo;
                    var municipality = (compInfo.companyAddress.municipality == "" || compInfo.companyAddress.municipality == null) ? compInfo.companyAddress.postArea : compInfo.companyAddress.municipality;
                    var companyType = compInfo.industries.reduce(function (str, val) { return str = str + val.industryDescription + ", "; }, '').replace(/,(?=\s*$)/, '');
                    //Change frontend to use values from store aswell so we only need to set them once: christian 2021-11-16
                    vueComp.$store.commit('changeOrAdd', { companyName: compInfo.name });
                    vueComp.$store.commit('changeOrAdd', { companyType: companyType });
                    vueComp.$store.commit('changeOrAdd', { streetAddress: compInfo.companyAddress.streetAddress });
                    vueComp.$store.commit('changeOrAdd', { postalCode: compInfo.companyAddress.zipCode });
                    vueComp.$store.commit('changeOrAdd', { city: municipality });
                    vueComp.companyInfo.companyName = compInfo.name;
                    vueComp.companyInfo.street = compInfo.companyAddress.streetAddress;
                    vueComp.companyInfo.city = compInfo.companyAddress.zipCode + " " + municipality;
                    vueComp.companyInfo.companyType = companyType
                    vueComp.isLoggedIn = true;
                    //If session has expired and login is triggered again, ignore setting active step because we are already on step 3: Christian 2022-02-16
                    if (!vueComp.bankIdSessionExpired) {
                        vueComp.activeStep = vueComp.hasWarning ? vueComp.activeStep : vueComp.activeStep + 1;
                    }
                } else {
                    //Company wasnt found in eureka API, display not-connected modal
                    vueComp.$refs["not-connected-modal"].show();
                }
            },
            registerNewClient(isQrSign) {
                var vueComp = this;
                vueComp.abortSign = false;
                vueComp.$refs["bankid-sign-modal"].show();
                vueComp.isQr = isQrSign;

                return vueComp.setupDocumentSign();
            },
            createNewClient() {
                var vueComp = this;
                vueComp.showLoader = true;
                axios.post("/api/onboarding/RegisterNewClient", vueComp.$store.state)
                    .then(function (response) {
                        console.log("register new client:", response.data);
                        if (response.data.i4ErrorText != null || response.data.i5ErrorText != null) {
                            //Something went wrong while creating client, open error modal?
                            //vueComp.bankIdLoadingText = "Något gick fel, vänligen försök igen.";
                            vueComp.showModalErrorText = true;
                            vueComp.modalErrorText = "Något gick fel, vänligen försök igen.";
                            return;
                        }
                        vueComp.$refs["bankid-sign-modal"].hide();
                        vueComp.$refs["confirmation-modal"].show();
                    }).catch(function (response) {
                        console.log("error creating client", response);
                        vueComp.correlationId = response.response.headers['x-svea-correlationid'];
                        vueComp.$refs["error-modal"].show();
                    }).finally(function () {
                        vueComp.showLoader = false;
                        vueComp.activeStep = 4;
                    });
            },
            //new commoneid auth
            async initEidAuth(isQrAuth) {
                event.preventDefault();
                let vueComp = this;
                vueComp.isQr = isQrAuth;
                try {
                    if (vueComp.isQr) {
                        vueComp.setupQr(false);
                    }
                    vueComp.showLoader = true;
                    vueComp.showBankIdLoadingText = true;
                    vueComp.showModalErrorText = false;
                    vueComp.abortAuth = false;
                    vueComp.disableCancelBtn = false;
                    vueComp.bankIdLoadingText = "";

                    //fetch
                    const url = "/api/auth/InitAuth";
                    const options = {
                        method: "post",
                        headers: {
                            "Accept": "application/json",
                            "Content-Type": "application/json;charset=UTF-8",
                        },
                        body: JSON.stringify({
                            organisationNumber: formatOrganisationNumber(vueComp.organisationNumber),
                            fakeSsn: vueComp.fakeSsn ?? "",
                            authMode: vueComp.authMode
                        })
                    };
                    let response = await fetch(url, options);
                    if (response.ok) {
                        let responseData = await response.json();
                        if (!vueComp.abortAuth && responseData.data.isSuccessStatusCode) {
                            vueComp.bankIdOrderRef = responseData.data.orderRef
                            vueComp.bankIdAutoStartToken = responseData.data.autoStartToken
                            vueComp.setTheInterval('auth');
                            if (vueComp.isQr) {
                                vueComp.setQrInterval(false);
                            } else {
                                if (!vueComp.enableFakeSsn || vueComp.authMode !== "fake") {
                                    vueComp.openBankIdApp("auth");
                                }
                            }
                        } else {
                            vueComp.handleInitAuthErrors();
                            vueComp.$refs["error-modal"].show();
                        }
                    } else {
                        vueComp.handleInitAuthErrors();
                        vueComp.$refs["error-modal"].show();
                    }
                } catch {
                    vueComp.handleInitAuthErrors();
                    vueComp.$refs["error-modal"].show();
                }
            },
            handleInitAuthErrors() {
                let vueComp = this;
                console.log("error initializing bankid");
                vueComp.bankIdOrderRef = null;
                vueComp.bankIdAutoStartToken = null;
                vueComp.showLoader = false;
            },
            async getEidAuthStatus() {
                var vueComp = this;
                vueComp.totalAttemptForStatus++;

                if (vueComp.totalAttemptForStatus > 40) {
                    vueComp.handleStatusErrors();
                    return false;
                }
                if (vueComp.abortAuth) {
                    vueComp.handleStatusErrors();
                    return;
                }

                //fetch
                try {
                    const url = "/api/auth/GetAuthStatus";
                    const options = {
                        method: "post",
                        headers: {
                            "Accept": "application/json",
                            "Content-Type": "application/json;charset=UTF-8",
                        },
                        body: JSON.stringify({
                            orderRef: vueComp.bankIdOrderRef
                        })
                    };
                    let response = await fetch(url, options);
                    if (response.ok) {
                        let responseData = await response.json();
                        let status = responseData.data.status;

                        if (status === "failed") {
                            vueComp.handleAuthStatusErrors();
                        }
                        if (status === 'complete') {
                            vueComp.abortStatusInterval();
                            vueComp.abortQrInterval();
                            return vueComp.verifyEidAuth();
                        }
                    } else {
                        vueComp.handleAuthStatusErrors();
                        vueComp.$refs["error-modal"].show();
                    }
                } catch (statusError) {
                    vueComp.handleAuthStatusErrors()
                    vueComp.$refs["error-modal"].show();
                }
            },
            handleAuthStatusErrors() {
                let vueComp = this;
                vueComp.abortAuth = true;
                vueComp.abortStatusInterval();
                vueComp.abortQrInterval();
                vueComp.showLoader = false;
            },
            async getEidAuthQrData() {
                var vueComp = this;

                const url = "/api/auth/GetAuthQrData";
                const options = {
                    method: "post",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json;charset=UTF-8",
                    },
                    body: JSON.stringify({
                        orderRef: vueComp.bankIdOrderRef
                    })
                };
                let response = await fetch(url, options);

                if (response.ok) {
                    let responseData = await response.json();
                    if (!vueComp.abortAuth) {
                        vueComp.showQr = true;
                        vueComp.qr.set({
                            foreground: 'black',
                            value: responseData.data.data
                        });
                    }
                } else {
                    vueComp.showQr = false;
                }
            },
            async cancelEidAuth() {
                var vueComp = this;
                //Only run cancel request if there is an active request, otherwise we will trigger a 500 error: Christian 2022-09-26
                if (vueComp.bankIdOrderRef) {
                    try {
                        vueComp.abortAuth = true;
                        //fetch
                        const url = "/api/auth/CancelAuth";
                        const options = {
                            method: "post",
                            headers: {
                                "Accept": "application/json",
                                "Content-Type": "application/json;charset=UTF-8",
                            },
                            body: JSON.stringify({
                                orderRef: vueComp.bankIdOrderRef
                            })
                        };
                        let response = await fetch(url, options);
                        if (response.ok) {
                            console.log("auth request cancelled");
                        }
                    } catch {
                        console.log("error cancelling auth request");
                    } finally {
                        vueComp.abortStatusInterval();
                        vueComp.abortQrInterval();
                        vueComp.showLoader = false;
                        vueComp.bankIdOrderRef = null;
                        vueComp.bankIdAutoStartToken = null;
                        vueComp.hideBankIdModal();
                        vueComp.disableCancelBtn = false;
                    }
                } else {
                    vueComp.hideBankIdModal();
                }
            },
            async verifyEidAuth() {
                try {
                    var vueComp = this;

                    const url = "/api/auth/verifyuser";
                    const options = {
                        method: "post",
                        headers: {
                            "Accept": "application/json",
                            "Content-Type": "application/json;charset=UTF-8",
                        },
                        body: JSON.stringify({
                            organisationNumber: formatOrganisationNumber(vueComp.organisationNumber),
                            orderRef: vueComp.bankIdOrderRef
                        })
                    };

                    let response = await fetch(url, options);
                    if (response.ok) {
                        let responseData = await response.json();
                        console.log("VerifyAuth");

                        let verifyResponse = responseData.verifyResponse;
                        let canBecomeClientResponse = responseData.canBecomeClientResponse;
                        let clientExistsResponse = responseData.clientExistsResponse;
                        let regTime = responseData.regTime;

                        vueComp.bankIdLoadingText = verifyResponse.status;
                        vueComp.companyInfo.representativeName = verifyResponse.fullName;
                        vueComp.$store.commit('changeOrAdd', { fullName: verifyResponse.fullName });
                        vueComp.$store.commit('changeOrAdd', { regTime: regTime });

                        //adding personalnumber to to use in signing
                        vueComp.$store.commit('changeOrAdd', { personalNumber: verifyResponse.personalNumber });
                        //Adding formatted orgnr to store here so user cant change but the correct value gets sent to backend to match with the value in the cookie: Christian 2023-01-31
                        vueComp.$store.commit('changeOrAdd', { organisationNumber: formatOrganisationNumber(vueComp.organisationNumber) });
                        vueComp.disableCancelBtn = true;

                        //This means canBecomeClient = false so the clientExists check hasnt been run. We still need to set the data to correctly show error card + some info: Christian 2022-10-18
                        if (!clientExistsResponse) {
                            vueComp.setCanBecomeClientdata(canBecomeClientResponse);
                            return;
                        }

                        if (clientExistsResponse.i4CustomerExists && clientExistsResponse.i5UserExists) {
                            //user already exists in both i4 and i5
                            vueComp.$refs["already-registered-modal"].show();
                            return;
                        }
                        if (clientExistsResponse.i4CustomerExists && !clientExistsResponse.i5UserExists) {
                            //customer exists in i4 but doesnt have an i5 account
                            vueComp.$refs["non-user-modal"].show();
                            return;
                        }
                        if (clientExistsResponse.i5UserExists && !clientExistsResponse.i4CustomerExists) {
                            //user has an i5 account but doesnt exist as a customer in i4
                            vueComp.$refs["user-exist-no-company-modal"].show();
                            return;
                        }

                        if (!clientExistsResponse.i5UserExists && !clientExistsResponse.i4CustomerExists) {
                            //HAPPY FLOW, neither i5user nor i4customer exists and canBecomeClient = true
                            //Put this into a function to make this success call easier to read: Christian 2022-10-11
                            vueComp.setCanBecomeClientdata(canBecomeClientResponse);
                        }
                    }
                } catch {
                    console.log("verify auth error");
                    vueComp.abortAuth = true;
                    vueComp.abortStatusInterval();
                    vueComp.abortQrInterval();
                    vueComp.$refs["error-modal"].show();
                } finally {
                    vueComp.disableCancelBtn = false;
                    vueComp.showLoader = false;
                    vueComp.showQr = false;
                    vueComp.hideBankIdModal();
                    if (vueComp.bankIdSessionExpired) {
                        vueComp.$refs["bankid-sign-modal"].hide();
                        vueComp.bankIdSessionExpired = false;
                    }
                }
            },
            abortStatusInterval() {
                var vueComp = this;
                if (vueComp.statusInterval) {
                    vueComp.totalAttemptForStatus = 0;
                    clearInterval(vueComp.statusInterval);
                    vueComp.statusInterval = null;
                }
            },
            abortQrInterval() {
                var vueComp = this;
                vueComp.showQr = false;
                if (vueComp.qrInterval) {
                    clearInterval(vueComp.qrInterval);
                    vueComp.qrInterval = null;
                }
            },
            setTheInterval(bankIdType) {
                var vueComp = this;
                vueComp.abortStatusInterval();

                if (bankIdType === 'auth') {
                    if (!vueComp.abortAuth) {
                        let isCheckingAuth = true;
                        vueComp.getEidAuthStatus().then(() => { isCheckingAuth = false });

                        vueComp.statusInterval = setInterval(() => {
                            if (!isCheckingAuth) {
                                isCheckingAuth = true;
                                vueComp.getEidAuthStatus().then(() => { isCheckingAuth = false });
                            }
                        }, 2000);
                    }
                }
                if (bankIdType === 'sign') {
                    if (!vueComp.abortSign) {
                        let isCheckingAuth = true;
                        vueComp.getEidSignStatus().then(() => { isCheckingAuth = false });
                        vueComp.statusInterval = setInterval(() => {
                            if (!isCheckingAuth) {
                                isCheckingAuth = true;
                                vueComp.getEidSignStatus().then(() => { isCheckingAuth = false });
                            }
                        }, 2000);
                    }
                }
            },
            setupQr(isSign) {
                let vueComp = this;
                if (isSign) {
                    vueComp.qrElement = vueComp.$refs["qrSignImg"];
                } else {
                    vueComp.qrElement = vueComp.$refs["qrAuthImg"];
                }
                
                if (vueComp.qrElement !== undefined && vueComp.qrElement !== null) {
                    vueComp.showQr = false;
                    vueComp.qr = new QRious({
                        element: vueComp.qrElement
                    });
                }
            },
            //TODO: set signersReferenceId in cookie and remove it here
            setupDocumentSign() {
                var vueComp = this;
                vueComp.showLoader = true;
                vueComp.bankIdLoadingText = "";
                
                vueComp.showBankIdLoadingText = true;
                vueComp.showModalErrorText = false;

                return axios.post("/api/auth/SetupDocumentSign", vueComp.$store.state)
                    .then(function (response) {
                        console.log("SetupDocumentSign", response)
                        if (!vueComp.abortSign) {
                            vueComp.signersReferenceId = response.data.signers[0].referenceId;
                            vueComp.documentReferenceId = response.data.SigningReferenceId
                            vueComp.$store.commit('changeOrAdd', { signersReferenceId: vueComp.signersReferenceId });
                            return vueComp.initEidSign(vueComp.signersReferenceId);
                        }
                    }).catch(function (error) {
                        console.log("Error setting up document sign", error);
                        //If response is 401 then session has expired. Trigger login flow again: Christian 2022-02-14
                        if (error.response.status == 401) {
                            vueComp.bankIdLoadingText = "Det tog för lång tid för identifieringen att slutföras. Försök gärna igen.";
                            vueComp.showModalErrorText = true;
                            vueComp.modalErrorText = "Det tog för lång tid för identifieringen att slutföras. Försök gärna igen.";
                            vueComp.bankIdSessionExpired = true;
                        }
                        else {
                            //If error but response is not 401 then something went wrong with external API requests: 2022-05-11
                            vueComp.bankIdLoadingText = "Något gick fel, försök gärna igen.";
                            vueComp.showModalErrorText = true;
                            vueComp.modalErrorText = "Något gick fel, försök gärna igen.";
                        }
                        vueComp.showLoader = false;
                    }).finally(function () {
                        //vueComp.bankIdLoadingText = "Initializing";
                    });
            },
            //TODO: set signersReferenceId in cookie and remove it here
            async initEidSign(signersReferenceId) {
                let vueComp = this;
                vueComp.abortSign = false;
                vueComp.disableCancelBtn = false;
                if (vueComp.isQr) {
                    vueComp.setupQr(true);
                }

                const url = '/api/auth/InitSign';
                const options = {
                    method: "post",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json;charset=UTF-8",
                    },
                    body: JSON.stringify({
                        signingReferenceId: signersReferenceId,
                        personalNumber: vueComp.personalNumber,
                        organisationNumber: vueComp.organisationNumber,
                        authMode: vueComp.authMode
                    })
                };

                try {
                    const response = await fetch(url, options);
                    if (response.ok) {
                        let responseData = await response.json();
                        if (!vueComp.abortSign) {
                            vueComp.bankIdOrderRef = responseData.data.orderRef;
                            vueComp.bankIdAutoStartToken = responseData.data.autoStartToken
                            vueComp.setTheInterval('sign');
                            if (vueComp.isQr) {
                                vueComp.setQrInterval(true);
                            } else {
                                if (!vueComp.enableFakeSsn || vueComp.authMode !== "fake") {
                                    vueComp.openBankIdApp("sign");
                                }
                            }
                        }
                    } else {
                        vueComp.handleInitSignError();
                        vueComp.$refs["error-modal"].show();
                    }
                } catch {
                    vueComp.handleInitSignError();
                    vueComp.$refs["error-modal"].show();
                }
            },
            handleInitSignError() {
                let vueComp = this;
                console.log("error initializing sign with bankid");
                vueComp.abortSign = true;
                vueComp.bankIdOrderRef = null;
                vueComp.showLoader = false;
                vueComp.$refs["error-modal"].show();
            },
            //TODO: set orderref in cookie and remove it here
            async getEidSignStatus() {
                var vueComp = this;
                const url = '/api/auth/GetSignStatus';
                const options = {
                    method: "post",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json;charset=UTF-8",
                    },
                    body: JSON.stringify({
                        orderRef: vueComp.bankIdOrderRef,
                        personalNumber: vueComp.personalNumber,
                        organisationNumber: vueComp.organisationNumber
                    })
                };

                try {
                    if (vueComp.abortSign) {
                        vueComp.handleSignStatusError();
                        return false;
                    }

                    const response = await fetch(url, options);
                    if (response.ok) {
                        let responseData = await response.json();

                        if (responseData.data.status === 'failed') {
                            vueComp.abortSign = true;
                            vueComp.handleSignStatusError();
                        }
                        if (responseData.data.status === 'complete') {
                            vueComp.abortStatusInterval();
                            vueComp.abortQrInterval();
                            return vueComp.verifyEidSign();
                        }
                    } else {
                        console.log("error checking status of bankid");
                        vueComp.abortSign = true;
                        vueComp.handleSignStatusError();
                        vueComp.$refs["error-modal"].show();
                    }
                } catch {
                    console.log("error checking status of bankid");
                    vueComp.abortSign = true;
                    vueComp.handleSignStatusError();
                    vueComp.$refs["error-modal"].show();
                }
            },
            handleSignStatusError() {
                let vueComp = this;
                vueComp.showLoader = false;
                vueComp.abortStatusInterval();
                vueComp.abortQrInterval();
            },
            //TODO: set orderref in cookie and remove it here
            async getEidSignQrData() {
                var vueComp = this;

                const url = "/api/auth/GetSignQrData";
                const options = {
                    method: "post",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json;charset=UTF-8",
                    },
                    body: JSON.stringify({
                        orderRef: vueComp.bankIdOrderRef,
                        personalNumber: vueComp.personalNumber,
                        organisationNumber: vueComp.organisationNumber
                    })
                };
                let response = await fetch(url, options);
                try {
                    if (response.ok) {
                        let responseData = await response.json();
                        if (!vueComp.abortSign) {
                            vueComp.showQr = true;
                            vueComp.qr.set({
                                foreground: 'black',
                                value: responseData.data.data
                            });
                        } else {
                            vueComp.abortQrInterval();
                        }
                    } else {
                        vueComp.abortQrInterval();
                    }
                } catch {
                    vueComp.abortQrInterval();
                }
            },
            //TODO: set orderref in cookie and remove it here
            async verifyEidSign() {
                var vueComp = this;
                const url = '/api/auth/VerifySign';
                const options = {
                    method: "post",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json;charset=UTF-8",
                    },
                    body: JSON.stringify({
                        orderRef: vueComp.bankIdOrderRef,
                        personalNumber: vueComp.personalNumber,
                        organisationNumber: vueComp.organisationNumber
                    })
                };
                try {
                    const response = await fetch(url, options);

                    if (response.ok) {
                        let responseData = await response.json();
                        if (!vueComp.abortSign && responseData.data.status === 'complete') {
                            vueComp.$store.commit('changeOrAdd', { bankIdSignToken: vueComp.bankIdOrderRef });
                            vueComp.bankIdOrderRef = null;
                            vueComp.createNewClient();
                        } else {
                            console.log("signing with bankid was aborted");
                        }
                    } else {
                        console.log("error when verifying signing with bankid");
                        vueComp.$refs["error-modal"].show();
                    }
                    
                } catch {
                    console.log("error when verifying signing with bankid");
                    vueComp.$refs["error-modal"].show();
                }
            },
            //TODO: set orderref in cookie and remove it here
            async cancelEidSign() {
                var vueComp = this;
                vueComp.abortSign = true;
                vueComp.abortStatusInterval();
                vueComp.abortQrInterval();
                const url = '/api/auth/CancelSign';
                const options = {
                    method: "post",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json;charset=UTF-8",
                    },
                    body: JSON.stringify({
                        orderRef: vueComp.bankIdOrderRef,
                        personalNumber: vueComp.personalNumber,
                        organisationNumber: vueComp.organisationNumber
                    })
                };

                try {
                    const response = await fetch(url, options);
                    if (response.ok) {
                        console.log("sign request cancelled");
                    }
                } catch {
                    console.log("error cancelling sign request");
                } finally {
                    vueComp.showLoader = false;
                    vueComp.bankIdSignToken = null;
                    vueComp.bankIdOrderRef = null;
                    vueComp.bankIdAutoStartToken = null;
                    vueComp.disableCancelBtn = false;
                    vueComp.$refs["bankid-sign-modal"].hide();
                }
            },
            setQrInterval(isSign) {
                let vueComp = this;
                vueComp.abortQrInterval();

                if (!vueComp.abortSign || !vueComp.abortAuth) {
                    if (isSign) {
                        vueComp.getEidSignQrData();
                        vueComp.qrInterval = setInterval(() => {
                            vueComp.getEidSignQrData();
                        }, 1000);
                    } else {
                        vueComp.getEidAuthQrData();
                        vueComp.qrInterval = setInterval(() => {
                            vueComp.getEidAuthQrData();
                        }, 1000);
                    }
                }
            },
            //new commoneid auth
            hideBankIdModal() {
                this.$refs["bankid-modal"].hide();
            },
            hideNoCompanyFoundModal() {
                this.$refs["not-connected-modal"].hide();
            },
            submitInfo() {
                event.preventDefault();
                this.$v.contactInfo.$touch();
                if (this.$v.contactInfo.phoneNumber.$error
                    || this.$v.contactInfo.emailAddress.$error
                    || (this.$v.contactInfo.bankAccountNumber.$error && this.$v.contactInfo.postAccountNumber.$error)
                    || this.$v.contactInfo.invoiceReference.$error
                    || this.$v.contactInfo.invoiceReferenceEmail.$error) {
                    return;
                }
                this.isContactInfoSubmited = true;
                this.title2 = "AboutYourCompany";
                this.activeStep++;
            },
            stepClass(stepNumber) {
                return {
                    "active-step d-flex justify-content-center align-items-center":
                        this.activeStep === stepNumber,
                    "inactive-step d-flex justify-content-center align-items-center":
                        this.activeStep !== stepNumber,
                };
            },
            editBankId() {
                this.activeStep = 1;
                this.isLoggedIn = false;
                this.isContactInfoSubmited = false;
            },
            editContactInfo() {
                this.activeStep = 2;
                this.isContactInfoSubmited = false;
            },
            openKundwebInNewWindow() {
                let vueComp = this;
                axios.post("/api/kundwebb/baseurl", {
                    personalNumber: vueComp.personalNumber,
                    organisationNumber: vueComp.organisationNumber
                }).then(baseUrl => {
                    //needed this timeout to get Vue to finish the render stuff
                    //maybe can be made with some vue specific nice event to hookup to
                    setTimeout(() => {
                        window.open(baseUrl.data, '_blank');
                    }, 1);
                });
            },
            gotoKundwebb() {
                let vueComp = this;
                return axios
                    .post("/api/kundwebb/token/create", {
                        personalNumber: vueComp.personalNumber,
                        organisationNumber: formatOrganisationNumber(vueComp.organisationNumber)
                    })
                    .then(token => {
                        vueComp.kundwebbLoginFormData = token.data;
                        //TODO: do we have some kind of place for settings so we can download all appsettings applicable for client ?
                        axios.post("/api/kundwebb/baseurl", {
                            personalNumber: vueComp.personalNumber,
                            organisationNumber: vueComp.organisationNumber
                        }).then(baseUrl => {
                            vueComp.kundwebbLoginFormAction = baseUrl.data + "/token/authenticate";

                            //needed this timeout to get Vue to finish the render stuff
                            //maybe can be made with some vue specific nice event to hookup to
                            setTimeout(() => {
                                document.querySelector("#kundwebbLoginForm").submit();
                            }, 1);
                        });
                    })
                    .catch(error => alert(error));
            }
        },
        validations: {
            bankId: {
                ssn: { required, isValidSsn },
                orgNumber: { required, isValidOrgnumber }
            },
            contactInfo: {
                phoneNumber: { required },
                emailAddress: { required },
                bankAccountNumber: { requiredUnlessPg: requiredUnless("postAccountNumber") },
                postAccountNumber: { requiredUnlessBg: requiredUnless("bankAccountNumber") },
                invoiceReference: { required },
                invoiceReferenceEmail: { required }
            },
            companyInfo: {
                street: { required }
            }
        }
    };
</script>
<style lang="scss">

</style>