import Vue from 'vue'
import VueI18n from 'vue-i18n'
import axios from 'axios'
import defaultLang from '../src/locales/sv-SE'

export const getPrices = async function () {
    var prices = await axios.get("/api/phrase/prices");
    var priceObject = prices.data.reduce((acc, curr) => {
        acc[curr.priceType] = curr.priceValue
        return acc;
    }, {});
    return priceObject;
}

Vue.use(VueI18n)

export const i18n = new VueI18n({
    locale: 'sv-SE',
    fallbackLocale: 'en-GB',
    messages: { "sv-SE": defaultLang }
})

function setI18nLanguage(lang) {
    i18n.locale = lang
    axios.defaults.headers.common['Accept-Language'] = lang
    document.querySelector('html').setAttribute('lang', lang)
    return lang
}

const mergePricesWithPhrases = (prices, phrases) => {
    return Object.assign(phrases, {
        "ProductPackageValue": { "message": prices["W230_ARSAVG"] },
        "AgentFeesUnpaidClaimValue": { "message": prices["W635_OMBARV"] },
        "AgentFeesWithdrawnCaseValue": { "message": prices["W635_OMBARV"] },
        "ApplicationForInjunctionToPayUnpaidClaimValue": { "message": prices["W635_STLAVG"] },
        "ApplicationForInjunctionToPayWithdrawnCaseValue": { "message": prices["W635_STLAVG"] },
        "DebtcollectionFeeUnpaidClaimValue": { "message": 0 },
        "DebtcollectionFeeWithdrawnCaseValue": { "message": prices["W635_INKASS"] },
        "ForeclosureUnpaidClaimValue": { "message": prices["W635_KFMAVG"] },
        "ForeclosureWithdrawnCaseValue": { "message": prices["W635_KFMAVG"] },
        "LateFeeAtReminderValue": { "message": prices["W233_FER"] },
        "RemindersValue": { "message": prices["W231_HANTERINGSAVG"] },
        "SveaLegalFeesValue": { "message": `${prices["W231_JURIST"] * 4} ${phrases["SveaLegalFeesValue"].message}` },
        "ExtraInvestigationValue": { "message": `${prices["W231_TIMARVODE"]} ${phrases["ExtraInvestigationValue"].message}` },
        "AddonServicesRemindersContentText": {
            "message": phrases["AddonServicesRemindersContentText"]
                .message
                .replace("#{W635_AVIAVG}", prices["W635_AVIAVG"])
                .replace("#{W231_HANTERINGSAVG}", prices["W231_HANTERINGSAVG"])
        },
        "AddonServicesDelayCompContentText": {
            "message": phrases["AddonServicesDelayCompContentText"]
                .message
                .replace("#{W667_FER}", prices["W667_FER"])
                .replace("#{W663_PROC_FER}", prices["W663_PROC_FER"])
        },
        "AddonServicesDelayCompModalText": {
            "message": phrases["AddonServicesDelayCompModalText"]
                .message
                .replace("#{W667_FER}", prices["W667_FER"])
                .replace("#{W663_PROC_FER}", prices["W663_PROC_FER"])
                .replace("#{W233_FER}", prices["W233_FER"])
        }
    });
}

export const loadLanguage = async function loadLanguageAsync(lang) {
    var pricesObject = await getPrices();

    // this we do cause if the phrase integration for some reason do not work we will have the comitted phrases plus prices from idun still
    var defaultLangWithPrices = mergePricesWithPhrases(pricesObject, defaultLang);
    i18n.setLocaleMessage(lang, defaultLangWithPrices);

    return axios.get("/api/phrase/all").then(
        async messages => {
            if (messages.data) {
                var mergedData = mergePricesWithPhrases(pricesObject, messages.data);
                i18n.setLocaleMessage(lang, mergedData)
                return setI18nLanguage(lang)
            }

            return lang;
        }
    );
}