import Vue from 'vue'
import { i18n } from '../i18n.js'

Vue.filter('setCurrency', value => {
    if (isNaN(value)) { return value }
    if (i18n.locale == 'sv-SE') {
        return value + ' kr';
    } else {
        return value + ' SEK';
    }
});