<template>

  <div>
    <div class="reminders-box">
      <div class="title">{{ dummyData.reminders.title | translate }}</div>
      <div class="description">{{ dummyData.reminders.description | translate }}</div>
    </div>


    <div class="form-check add-reminders-checkbox">
      <input class="form-check-input" type="checkbox" id="add-reminders-checkbox"
             v-model="addRemindersCheckbox">
      <label class="form-check-label" for="add-reminders-checkbox">{{ dummyData.addRemindersCheckbox.label | translate }}</label>
    </div>

    <div class="delay-compensation-box">
      <div class="title">{{ dummyData.delayCompensation.title | translate }}</div>
      <div class="description">
          {{ dummyData.delayCompensation.description | translate }}
          <a v-b-modal="'delaycompensation-modal'" style="color: #00325c">{{ dummyData.delayCompensation.link.text | translate }}</a>
      </div>
    </div>

    <b-modal ref="delaycompensation-modal"
             id="delaycompensation-modal"
             size="lg"
             centered
             hide-footer>
        <div class="container px-5 my-4">
            <p class="my-1 modal-title text-left">{{ dummyData.delayCompensation.title | translate }}</p>
            <div class="content-text text-left my-4" style="white-space: pre-wrap;">{{ 'AddonServicesDelayCompModalText' | translate }}</div>
        </div>
    </b-modal>

    <div class="delay-compensation-options">
      <div class="form-check" v-for="option in dummyData.delayCompensation.options" :key="option.index">
        <input :disabled="option.value === 1 && !addRemindersCheckbox" class="form-check-input" type="radio" name="delayCompensationOption"
               :id="'delayCompensationOption-' + option.value" v-model="delayCompensationValue" v-bind:value="option.value">
        <label class="form-check-label" :for="'delayCompensationOption-' + option.value">
          {{ option.label | translate }}
        </label>
        <div id="compensationWarning" v-if="delayCompensationValue === 3 && option.warning.length > 0 && addRemindersCheckbox === false" class="mt-2 mb-2 bd-callout">
          <b-row>
            <b-col cols="1" lg="1" sm="1" class="d-flex justify-content-center align-items-center" style="margin-top: -10px;">
              <img src="../assets/warning.png" alt="warning" >
            </b-col>
            <b-col><p>{{ option.warning }}</p></b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

export default {
    name: "AdditionalService",
    data() {
        return {
            dummyData: dummyData
        }
    },
    computed: {
        addRemindersCheckbox: {
            get() {
                return this.$store.state.addReminder;
            },
            set() {
                if (this.addRemindersCheckbox && this.delayCompensationValue === 1) {
                    this.delayCompensationValue = 3;
                }
                this.$store.commit('changeOrAdd', { addReminder: !this.$store.state.addReminder });
            }
            
        },
        delayCompensationValue: {
            get() {
                return this.$store.state.delayCompensation;
            },
            set(value) {
                this.$store.commit('changeOrAdd', { delayCompensation: value });
            }
        }
    }
}

const dummyData = {
  reminders: {
    title: 'AddonServicesRemindersHeaderText',
    description: 'AddonServicesRemindersContentText'
  },
  addRemindersCheckbox: {
    label: 'AddonServicesAddRemindersCheckbox',
    value: false
  },
  delayCompensation: {
    title: 'AddonServicesDelayCompHeaderText',
    description: 'AddonServicesDelayCompContentText',
    link: {
      url: 'http://google.com',
      text: 'AddonServicesDelayCompReadMoreLink'
    },
    options: [
      {
        value: 1,
        label: 'AddonServicesDelayCompOption1',
        warning: 'För att ta ut förseningsersättning vid påminnelse måste du lägga till tjänsten Påminnelser.'
      },
      {
        value: 2,
        label: 'AddonServicesDelayCompOption2',
        warning: ''
      },
      {
        value: 3,
        label: 'AddonServicesDelayCompOption3',
        warning: ''
      }
    ]
  }
};

</script>

<style scoped>

.add-reminders-checkbox {
  margin: 20px 0 40px 0;
}

.delay-compensation-box .title,
.reminders-box .title {
  margin: 40px 50px 10px 0;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  text-align: left;
  color: #434343;
}

.delay-compensation-box .description a {
    text-decoration: underline;
}

.delay-compensation-options {
  margin-top: 20px;
}

.bd-callout{
  padding: 10px 0 0 20px;
  border: 1px solid white;
  background-color: #fff3cc;
  border-left-width: .25rem;
  border-radius: .25rem;
  border-left-color: #f29200;
  margin-left: -1.25rem;
}

@media (max-width: 481px) {
  #compensationWarning {
    display: none;
  }
}

</style>