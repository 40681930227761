<template>
  <div class="terms text-left">
    <h1>Allmänna villkor för inkassotjänster</h1>
    <p>2019-04-01</p>
    <h3>1. Uppdraget</h3>
    <p>
        Svea Inkasso AB, nedan kallat Svea, förbinder sig att åt kund, nedan kallad Uppdragsgivaren, 
        med beaktande av god branschsed och god etik utföra den eller de tjänster som markerats på 
        samarbetsavtalets första sida.
    </p>
    <h3>2. Avtalet</h3>
    <p>
        Förutom samarbetsavtalet med allmänna villkor gäller Sveas vid var tid gällande prislista samt
         – i förekommande fall – av Svea lämnad och av Uppdragsgivaren accepterad offert (nedan kallad 
         Offerten).Svea har rätt att justera villkoren för uppdraget och göra ändringar i gällande 
         prislista. Meddelande om justeringar och ändringar ska göras en månad innan de träder i kraft. 
         Priser kan även justeras om förutsättningar som anges i Offerten ändras. Avtal ska i övrigt för 
         att kunna göras gällande träffas skriftligt och vara undertecknat av Uppdragsgivaren och Svea.
    </p>
    <h3>3. Betalningsvillkor</h3>
    <p>
        Svea debiterar och fakturerar Uppdragsgivaren ersättningar, provisioner, arvoden, kostnader, 
        årsavgifter och utlägg (nedan benämnt Ersättningar) enligt vid var tid gällande prislista samt 
        eventuella kompletterande avtalsvillkor. Betalningsvillkor för Sveas fakturor framgår av aktuell 
        prislista.
        Svea har rätt att begära förskottsbetalning av Uppdragsgivaren och att ur inkasserade medel kvitta 
        eller 
        innehålla belopp motsvarande de Ersätt- ningar eller annan fordran som Svea har eller kan komma 
        att ha rätt till.
    </p>
    <h3>4. Redovisning</h3>
    <p>
        Svea tillhandahåller information om erhållna uppdrag och redovisning av vad som inkasserats av 
        kapital, ränta och kostnader. Utbetalning av klientmedel sker, om inget annat avtalats särskilt,
         efter avräkning av Ersättningar eller annan fordran enligt prislista och i förekommande fall 
         enligt Offerten.
    </p>
    <h3>5. Panträtt</h3>
    <p>
        Svea har generell panträtt i de fordringar som Uppdragsgivaren uppdrar åt Svea att inkassera till 
        täckande av Ersättningar eller andra fordringar som Svea kan ha på Uppdragsgivaren.
    </p>
    <h3>6. Informationsskyldighet och avbrytande av uppdrag</h3>
    <p>
        Uppdragsgivaren svarar för att överlämnat uppdrag avser fordran som fakturerats eller aviserats 
        Uppdragsgivarens gäldenär och att fordran är förfallen till betalning samt lagligen grundad. 
        Uppdragsgivaren förbinder sig att omgående meddela Svea om händelser som påverkar eller kan påverka 
        uppdragets handläggning (till exempel betalning eller bestri- dande). Uppdragsgivaren ska inneha 
        F-skattebevis.
        Svea har rätt att avböja eller avbryta hantering av hela eller delar av uppdrag om information som 
        är nödvändig för uppdrags fullgörande inte erhålls, uppdraget eller utförandet av det kan antas 
        strida mot lag eller förordning, stå i strid med god inkassosed eller innebär avsteg från god etik. 
        Svea har i de fallen rätt att omgående fakturera ut eventuellt uppkomna Ersättningar.
    </p>
    <h3>7. Återkallelse</h3>
    <p>
        Om Uppdragsgivaren återkallar provisions- eller efterbevakningsärenden har Svea rätt att debitera 
        uppkomna Ersättningar enligt gällande prislista samt kräva kompensation för bortfall av förväntade 
        intäkter.
    </p>
    <h3>8. Force majeure</h3>
    <p>
        Svea är inte ansvarig för följder av sådant hinder i verksamheten som förorsakats av myndigheternas 
        åtgärder, strejk, lockout, krig eller av omständigheter utanför Sveas kontroll.
    </p>
    <h3>9. Exklusivitet</h3>
    <p>
        I och med undertecknandet av avtal är Svea exklusiv leverantör till Upp- dragsgivaren av tjänster 
        enligt avtalet under avtalstiden.
    </p>
    <h3>10. Skadestånd</h3>
    <p>
        Svea är skyldig att betala ersättning för direkt skada endast när uppsåt eller grov vårdslöshet 
        föreligger. Sveas ersättningsskyldighet för skada begränsar sig till att omfatta ett sammanlagt belopp 
        om det lägre av ett prisbasbelopp och den ersättning Uppdragsgivaren under de tre månader som närmast 
        föregått skadetillfället erlagt till Svea för den del av upp- draget som ersättningsanspråket grundar 
        sig på. Svea är inte ersättnings- skyldig för indirekt skada. Uppdragsgivaren är skyldig ersätta Svea 
        för de kostnader som kan uppkomma om Uppdragsgivaren bryter mot sina åtaganden i detta avtal och 
        avtalsbrottet är mer än av ringa karaktär.
    </p>
    <h3>11. Personuppgifter och personuppgiftsansvar</h3>
    <p>
        11.1 Svea är personuppgiftsansvarig
        Svea har genom detta avtal i uppdrag att som personuppgiftsansvarig
        behandla personuppgifter för Uppdragsgivarens räkning. Ändamålet med behandling av personuppgifter 
        i inkassoverksamheten ska vara att uppnå betalning eller annan fullgörelse eller verkställighet på 
        ett effektivt sätt. 
        11.2 Sveas ansvar Med beaktande av behandlingens art, omfattning, sammanhang och ändamål samt 
        riskerna, av varierande sannolikhetsgrad och allvar, för fysiska personers rättigheter och friheter 
        ska Svea genomföra lämpliga tekniska och organisatoriska åtgärder för att säkerställa och kunna visa 
        att behandlingen utförs i enlighet med Dataskyddsförordningen.
        11.3 Inbyggt dataskydd och dataskydd som standard
        Med beaktande av den senaste utvecklingen, genomförandekostnader och behandlingens art, omfattning, 
        sammanhang och ändamål samt riskerna, av varierande sannolikhetsgrad och allvar, för fysiska perso- 
        ners rättigheter och friheter ska Svea, både vid fastställandet av vilka medel behandlingen utförs 
        med och vid själva behandlingen, genomföra lämpliga tekniska och organisatoriska åtgärder som är 
        utformade för ett effektivt genomförande av dataskyddsprinciper samt för integrering av de nödvändiga 
        skyddsåtgärderna i behandlingen, så att kraven i Data- skyddsförordningen uppfylls och den registrerades 
        rättigheter skyddas. Svea ska genomföra lämpliga tekniska och organisatoriska åtgärder för att, i 
        standardfallet, säkerställa att endast personuppgifter som är nöd- vändiga för varje specifikt ändamål 
        med behandlingen behandlas. Den skyldigheten gäller mängden insamlade personuppgifter, behandlingens 
        omfattning, tiden för deras lagring och deras tillgänglighet. Framför allt ska dessa åtgärder säkerställa 
        att personuppgifter i standardfallet inte utan den enskildes medverkan görs tillgängliga för ett 
        obegränsat antal fysiska personer.
        11.4 Sveas hantering av Uppdragsgivarens kontaktuppgifter
        Svea behandlar elektroniskt personuppgifter, såsom kontaktinformation till Uppdragsgivarens 
        kontaktpersoner, för att förse Uppdragsgivaren med tjänsterna enligt samarbetsavtalet och för 
        att administrera affärs- relationen med Uppdragsgivaren. Uppgifterna kan även komma att an- 
        vändas för affärsrapporter och statistiska analyser samt för att uppfylla gällande lagar och 
        förordningar. Svea kan komma att lämna ut uppgif- terna till andra företag inom Svea-koncernen, som 
        också kan använda in- formationen för de ändamål som beskrivs här. Alla registrerade personer har rätt 
        att på begäran få en kopia av de personuppgifter som är under behandling. De har också rätt att begära 
        rättelse, radering, begränsad behandling eller rikta annan invändning mot behandling av personupp- 
        gifterna. Uppgifterna raderas när Svea erhållit besked att personen inte längre är kontaktperson. 
        Ytterligare information kan erhållas genom att kontakta Svea via privacy@svea.com.
        11.5 Sveas hantering av Uppdragsgivarens kontaktdata för marknads- föringssyften
        Svea kan komma att använda Uppdragsgivarens kontaktuppgifter för
        att skicka nyhetsbrev, för att genomföra produktundersökningar, för att marknadsföra liknande produkter 
        och tjänster från Svea och för inbjud- ningar till event. Svea får överföra uppgifter avseende 
        Uppdragsgivaren och dess kontaktpersoner till andra bolag inom Svea-
        koncernen som får använda datan för ovannämnda syften i den utsträck- ning lagen tillåter. 
        Mottagaren av sådan marknadsföring kan avsäga
        sig ytterligare marknadskommunikation genom att kontakta Svea via privacy@svea.com.
        11.6 Medgivande och garanti
        Genom att tillhandahålla personuppgifter om kontaktpersoner garan- terar Uppdragsgivaren att den har 
        medgivande för att lämna ut upp- gifterna och att uppgifterna får användas för de syften och på det 
        sätt som beskrivs under 11.4 och 11.5.
        Uppdragsgivaren garanterar att personuppgifter som delas till Svea är korrekta och att det finns 
        laglig grund för behandlingen. Brister i denna garanti innebär avtalsbrott.
    </p>
    <h3>12. Uppsägning</h3>
    <p>
        Om skriftlig uppsägning inte skett från någondera parten senast tre månader före avtalsperiodens utgång, 
        förlängs avtalet automatiskt med ett år i taget.
        Svea har rätt att säga upp samarbetsavtalet i förtid om det beträffande uppdrag föreligger något av de 
        förhållanden som anges i punkt 6 andra stycket, Uppdragsgivaren är i dröjsmål med betalning av Sveas 
        fakturor i mer än en månad eller denne gjort sig skyldig till avtalsbrott av mer än ringa art.
    </p>
    <h3>13. Avslutning</h3>
    <p>
        Vid eventuellt upphörande av detta avtal – av annan anledning än sådan som anges i punkt 
        12 andra stycket – förbinder sig Svea att behandla pågående ärenden på oförändrat sätt.
    </p>
  </div>
</template>
<script>
export default {
    name: 'terms'
};
</script>
<style scoped>
h1 {
  font-family: Asap;
  font-size: 32px;
  font-weight: 600;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.16;
  letter-spacing: -0.44px;
  text-align: left;
  color: #00325c;
}

h3 {
  font-family: Asap;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: normal;
  text-align: left;
  color: #434343;
}

p {
    font-family: Asap;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: left;
  color: #434343;
}
</style>