<template>
    <b-card class="section text-left">
        <div class="accordion">
            <div class="accordion-item"
                    v-bind:class="accordionClasses">
                <b-row>
                    <b-col lg="8" md="10" sm="10" cols="10">
                        <div class="accordion-link" v-on:click.stop="toggleAccordion">
                            <h1>{{ title }}</h1>
                        </div>
                    </b-col>
                    <b-col lg="4" md="2" sm="2" cols="2" class="text-right">
                        <font-awesome-icon :icon="accordionArrow" size="1x" v-on:click.stop="toggleAccordion" />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col lg="8" md="10" sm="12">
                        <div class="accordion-body">
                            <div class="accordion-content">
                                <div>
                                    <span>{{ content }}</span>
                                </div>
                                <component v-if="currentComponent" v-bind:is="currentComponent"></component>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </div>
        </div>
    </b-card>

</template>

<script>
    export default {
        props: {
            title: String,
            content: String,
            currentComponent: String
        },
        data() {
            return {
                isOpen: true
            }
        },
        methods: {
            toggleAccordion: function () {
                this.isOpen = !this.isOpen;
            }
        },
        computed: {
            accordionClasses: function () {
                return {
                    'is-closed': !this.isOpen,
                    'is-primary': this.isOpen
                };
            },
            accordionArrow: function () {
                let faArrowLink;
                if(this.isOpen){
                    faArrowLink = 'angle-up';
                } else {
                    faArrowLink = 'angle-down';
                }
                return faArrowLink;
            }
        }
    }
</script>

<style scoped>

</style>