
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        fullName: "",
        personalNumber: "",
        organisationNumber: "",
        companyName: "",
        companyType: "",
        streetAddress: "",
        postalCode: "",
        city: "",
        addReminder: false,
        delayCompensation: 3,
        phoneNumber: "",
        emailAddress: "",
        bankAccountNumber: "",
        postAccountNumber: "",
        invoiceReference: "",
        invoiceReferenceEmail: "",
        signersReferenceId: "",
        bankIdSignToken: "",
        regTime: ""
    },
    mutations: {
        changeOrAdd(state, payload) {
            var key = Object.keys(payload)[0];
            state[key] = payload[key]
            //console.log("SET TRIGGER", payload, state[key]);
        }
    }
})
