<template>
    <div v-show="isLoading">
        <div class="spinner-border my-2" role="status" id="loading-spinner"></div>
    </div>
</template>

<script>
    export default {
        name: 'loader-spinner',
        props: {
            isLoading: Boolean
        },
        methods: {
        },
        computed: {
            
        }
    }
</script>